import classnames from 'classnames/bind'
import React, {
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'
import { PhoneInputProps } from 'react-phone-input-2'
import { useTranslate } from 'react-polyglot'

import { PhoneInput } from '~/components/Account/Forms/Phone'

import { useStyle } from '~/providers/StyleProvider'

import objectFilter from '~/utils/filter-object'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export function ErrorComponent({
  className,
  error,
  withDefaultErrorString,
}: {
  className?: string
  error?: FieldErrors
  withDefaultErrorString?: boolean
}) {
  const textErrorStyle = useStyle({ textPreset: 'text-10' })
  const t = useTranslate()

  const hasErrors = error?.type || error?.types?.length > 0
  const name = error?.ref?.name
  const errorStringBase = `error.${error?.type}`

  return (
    hasErrors && (
      <div className={cx(className, css.Errors)}>
        {error?.type && (
          <div className={cx(textErrorStyle)}>
            {t(
              `${errorStringBase}${
                name && !withDefaultErrorString ? `.${name}` : ''
              }`,
            )}
          </div>
        )}
        {error?.types?.map((err, index) => (
          <div key={`${err}${index}`} className={cx(textErrorStyle)}>
            {t(
              `error.${err}${
                name && !withDefaultErrorString ? `.${name}` : ''
              }`,
            )}
          </div>
        ))}
      </div>
    )
  )
}

export interface InputProps extends InputHTMLAttributes<any> {
  className?: string
  textarea?: boolean
  phone?: boolean
  rows?: number
  forwardRef?: any
  withError?: boolean
  addRequiredIndicatorOnLabel?: boolean
  validate?: Function | Object
  isRegistered?: boolean
  inputClassName?: string
  displayErrors?: boolean
  inline?: boolean
  withDefaultErrorString?: boolean
  onError?: (node: ReactNode) => void
  phoneProps?: PhoneInputProps
}

function Input({
  className,
  forwardRef,
  type,
  textarea,
  phone,
  rows,
  withError,
  name,
  children,
  required,
  placeholder,
  addRequiredIndicatorOnLabel,
  pattern,
  validate,
  min,
  max,
  minLength,
  maxLength,
  defaultValue,
  defaultChecked,
  isRegistered = true,
  displayErrors = true,
  inputClassName,
  inline,
  onError,
  phoneProps,
  withDefaultErrorString,
  ...inputProps
}: InputProps) {
  const { register, errors } = useFormContext() || {}
  const [error, setError] = useState<FieldErrors>(null)

  useEffect(() => {
    setError(errors?.[name])
  }, [errors])

  const placeHolder = placeholder
    ? placeholder + (addRequiredIndicatorOnLabel && required ? '*' : '')
    : null

  const inputTextStyle = useStyle({
    textPreset: inline ? 'text-13-18' : 'input-14-12',
  })

  const labelTextStyle = useStyle({ textPreset: 'input-label-10' })

  const validations = {
    required,
    pattern,
    min,
    max,
    minLength,
    maxLength,
    defaultValue,
    defaultChecked,
    validate,
  }

  const filteredValidations = objectFilter(
    validations,
    ([_, val]) => val !== undefined,
  )
  const validationsLength = Object.keys(filteredValidations).length

  const setRef = useCallback(
    (node) => setRefCallback(node),
    [filteredValidations],
  )

  const setRefCallback = (node) => {
    if (forwardRef) forwardRef.current = node

    if (register && (validationsLength === 0 || !isRegistered)) {
      register(node)
    } else if (register && validationsLength > 0) {
      register(filteredValidations)(node)
    }
  }

  const cssError = withError || error?.type || error?.types?.length > 0

  useEffect(() => {
    onError?.(error)
  }, [cssError])

  const onInputChange = (e) => {
    inputProps?.onChange && inputProps.onChange(e)
    setError(null)
  }

  return (
    <div
      className={cx(css.Input, className, css[type], {
        withError: cssError,
        inline,
      })}>
      {textarea ? (
        <textarea
          className={cx(css.textarea, className, inputTextStyle, {
            withError: cssError,
          })}
          rows={rows}
          name={name}
          placeholder={placeHolder}
          ref={setRef}
          {...inputProps}
          onChange={onInputChange}
        />
      ) : phone ? (
        <PhoneInput
          className={cx(css.phone, { withError: cssError })}
          ref={setRef}
          required={required}
          defaultValue={defaultValue ?? null}
          phoneProps={phoneProps}
          placeholder={placeHolder}
          {...inputProps}
        />
      ) : (
        <input
          {...inputProps}
          type={type}
          name={name}
          maxLength={maxLength}
          defaultChecked={defaultChecked}
          defaultValue={defaultValue}
          ref={setRef}
          placeholder={placeHolder}
          onChange={onInputChange}
          className={cx(
            inputTextStyle,
            css.input,
            inputClassName ? inputClassName : css.withoutCustomClass,
            {
              withError: cssError,
            },
          )}
        />
      )}
      {(type === 'text' ||
        type === 'tel' ||
        type === 'email' ||
        type === 'password') && (
        <label className={cx(labelTextStyle, css.label)}>{placeHolder}</label>
      )}
      {typeof children === 'function'
        ? children({ withError: cssError })
        : children}
      {displayErrors && (
        <ErrorComponent
          withDefaultErrorString={withDefaultErrorString}
          error={error}
        />
      )}
    </div>
  )
}

Input.defaultProps = {
  addRequiredIndicatorOnLabel: true,
  withDefaultErrorString: false,
}

export default Input
