import classNames from 'classnames/bind'
import React, { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2'

import Input, { InputProps } from '~/components/Form/Input'

import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import css from './styles.module.scss'

const cx = classNames.bind(css)

export interface PhoneProps extends InputProps {
  className?: string
  phoneProps?: PhoneInputProps
  ref?: any
}

export function PhoneInput({
  className,
  ref,
  required,
  defaultValue,
  placeholder,
  phoneProps,
  ...inputProps
}: PhoneProps) {
  const inputTextStyle = useStyle({ textPreset: 'input-14-12' })
  const locale = useLocale()
  const country = useMemo(() => {
    const splittedLocale = locale?.split('-')
    return splittedLocale?.[1] ?? splittedLocale?.[0] ?? 'fr'
  }, [locale])

  return (
    <Controller
      name="phone"
      ref={ref}
      rules={{ required }}
      defaultValue={defaultValue ?? null}
      as={
        <ReactPhoneInput
          jumpCursorToEnd
          country={country}
          preferredCountries={['fr', 'us']}
          regions={['europe', 'us']}
          specialLabel={null}
          containerClass={cx(
            css.Phone,
            css.important,
            className,
            inputTextStyle,
          )}
          buttonClass={cx(css.button)}
          inputClass={cx(css.input, inputTextStyle)}
          inputProps={{ ...inputProps }}
          {...{ ...phoneProps, placeholder }}
        />
      }
    />
  )
}

function PhoneInputWrapper({ className, ...props }: PhoneProps) {
  return <Input className={className} phone {...props} />
}

PhoneInputWrapper.defaultProps = {
  required: false,
  name: 'phone',
}

export default PhoneInputWrapper
