import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm, UseFormOptions } from 'react-hook-form'
import { verifyRecaptcha } from '~/lib/handle-api'

import useAlerts from '~/hooks/useAlerts'

export default function useFormWithMutation(
  mutation: any,
  formOptions?: UseFormOptions,
  action?: string,
) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const triggerAlert = useAlerts()
  const form = useForm({
    ...formOptions,
  })

  const submitHandler = form.handleSubmit(async (data) => {
    let handleCaptcha

    if (action) {
      try {
        const token = await executeRecaptcha(action)
        handleCaptcha = await verifyRecaptcha({
          payload: { token, action },
        })

        if (handleCaptcha?.success) {
          mutation(data)
        } else triggerAlert('GOOGLE_RECAPTCHA_ERROR')
      } catch (err) {
        console.error('Recaptcha Error', err)
        triggerAlert('GOOGLE_RECAPTCHA_ERROR')
      }
    } else {
      mutation(data)
    }
  })

  return { form, onSubmit: submitHandler }
}
